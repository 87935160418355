import type { KnowledgeGraphEdge } from './knowledgeGraphDefinitions';

const knowledgeGraphEdges: KnowledgeGraphEdge[] = [
  { parentId: 'pitch_and_harmony', childId: 'piano_layout' },
  { parentId: 'piano', childId: 'piano_layout' },
  { parentId: 'pitch_and_harmony', childId: 'piano_c_g' },
  { parentId: 'piano', childId: 'piano_c_g' },
  { parentId: 'pitch_and_harmony', childId: 'piano_a_b' },
  { parentId: 'piano', childId: 'piano_a_b' },
  { parentId: 'pitch_and_harmony', childId: 'lines_spaces' },
  { parentId: 'treble_clef', childId: 'lines_spaces' },
  { parentId: 'pitch_and_harmony', childId: 'treble_c_g' },
  { parentId: 'treble_clef', childId: 'treble_c_g' },
  { parentId: 'pitch_and_harmony', childId: 'treble_a_g' },
  { parentId: 'treble_clef', childId: 'treble_a_g' },
  { parentId: 'pitch_and_harmony', childId: 'treble_low_legers' },
  { parentId: 'treble_clef', childId: 'treble_low_legers' },
  { parentId: 'pitch_and_harmony', childId: 'treble_high_legers' },
  { parentId: 'treble_clef', childId: 'treble_high_legers' },
  { parentId: 'pitch_and_harmony', childId: 'treble_clef_octave_numbers' },
  { parentId: 'treble_clef', childId: 'treble_clef_octave_numbers' },
  { parentId: 'pitch_and_harmony', childId: 'accidental_types' },
  { parentId: 'accidentals', childId: 'accidental_types' },
  { parentId: 'pitch_and_harmony', childId: 'sharps' },
  { parentId: 'accidentals', childId: 'sharps' },
  { parentId: 'pitch_and_harmony', childId: 'flats' },
  { parentId: 'accidentals', childId: 'flats' },
  { parentId: 'pitch_and_harmony', childId: 'naturals' },
  { parentId: 'accidentals', childId: 'naturals' },
  { parentId: 'pitch_and_harmony', childId: 'double_sharps' },
  { parentId: 'accidentals', childId: 'double_sharps' },
  { parentId: 'pitch_and_harmony', childId: 'double_flats' },
  { parentId: 'accidentals', childId: 'double_flats' },
  { parentId: 'pitch_and_harmony', childId: 'enharmonic_equivalence' },
  { parentId: 'accidentals', childId: 'enharmonic_equivalence' },
  { parentId: 'pitch_and_harmony', childId: 'bass_lines' },
  { parentId: 'bass_clef', childId: 'bass_lines' },
  { parentId: 'pitch_and_harmony', childId: 'bass_spaces' },
  { parentId: 'bass_clef', childId: 'bass_spaces' },
  { parentId: 'pitch_and_harmony', childId: 'bass_high_legers' },
  { parentId: 'bass_clef', childId: 'bass_high_legers' },
  { parentId: 'pitch_and_harmony', childId: 'bass_low_legers' },
  { parentId: 'bass_clef', childId: 'bass_low_legers' },
  { parentId: 'pitch_and_harmony', childId: 'bass_clef_octave_numbers' },
  { parentId: 'bass_clef', childId: 'bass_clef_octave_numbers' },
  { parentId: 'pitch_and_harmony', childId: 'half_steps_piano' },
  { parentId: 'half_and_whole_steps', childId: 'half_steps_piano' },
  { parentId: 'pitch_and_harmony', childId: 'whole_steps_piano' },
  { parentId: 'half_and_whole_steps', childId: 'whole_steps_piano' },
  { parentId: 'pitch_and_harmony', childId: 'chs' },
  { parentId: 'half_and_whole_steps', childId: 'chs' },
  { parentId: 'pitch_and_harmony', childId: 'dhs' },
  { parentId: 'half_and_whole_steps', childId: 'dhs' },
  { parentId: 'pitch_and_harmony', childId: 'ws' },
  { parentId: 'half_and_whole_steps', childId: 'ws' },
  { parentId: 'pitch_and_harmony', childId: 'major_scales_piano' },
  { parentId: 'major_scales', childId: 'major_scales_piano' },
  { parentId: 'pitch_and_harmony', childId: 'major_scales_staff' },
  { parentId: 'major_scales', childId: 'major_scales_staff' },
  { parentId: 'pitch_and_harmony', childId: 'note_in_key' },
  { parentId: 'major_key_signatures', childId: 'note_in_key' },
  { parentId: 'pitch_and_harmony', childId: 'order_sharps' },
  { parentId: 'major_key_signatures', childId: 'order_sharps' },
  { parentId: 'pitch_and_harmony', childId: 'treble_order_sharps' },
  { parentId: 'major_key_signatures', childId: 'treble_order_sharps' },
  { parentId: 'pitch_and_harmony', childId: 'bass_order_sharps' },
  { parentId: 'major_key_signatures', childId: 'bass_order_sharps' },
  { parentId: 'pitch_and_harmony', childId: 'sharp_majors' },
  { parentId: 'major_key_signatures', childId: 'sharp_majors' },
  { parentId: 'pitch_and_harmony', childId: 'order_flats' },
  { parentId: 'major_key_signatures', childId: 'order_flats' },
  { parentId: 'pitch_and_harmony', childId: 'treble_order_flats' },
  { parentId: 'major_key_signatures', childId: 'treble_order_flats' },
  { parentId: 'pitch_and_harmony', childId: 'bass_order_flats' },
  { parentId: 'major_key_signatures', childId: 'bass_order_flats' },
  { parentId: 'pitch_and_harmony', childId: 'flat_majors' },
  { parentId: 'major_key_signatures', childId: 'flat_majors' },
  { parentId: 'pitch_and_harmony', childId: 'c_major' },
  { parentId: 'major_key_signatures', childId: 'c_major' },
  { parentId: 'pitch_and_harmony', childId: 'circle_of_fifths' },
  { parentId: 'major_key_signatures', childId: 'circle_of_fifths' },
  { parentId: 'pitch_and_harmony', childId: 'imaginary_keys' },
  { parentId: 'major_key_signatures', childId: 'imaginary_keys' },
  { parentId: 'pitch_and_harmony', childId: 'natural_minor_piano' },
  { parentId: 'minor_scales', childId: 'natural_minor_piano' },
  { parentId: 'pitch_and_harmony', childId: 'natural_minor' },
  { parentId: 'minor_scales', childId: 'natural_minor' },
  { parentId: 'pitch_and_harmony', childId: 'harmonic_minor' },
  { parentId: 'minor_scales', childId: 'harmonic_minor' },
  { parentId: 'pitch_and_harmony', childId: 'harmonic_minor_piano' },
  { parentId: 'minor_scales', childId: 'harmonic_minor_piano' },
  { parentId: 'pitch_and_harmony', childId: 'melodic_minor' },
  { parentId: 'minor_scales', childId: 'melodic_minor' },
  { parentId: 'pitch_and_harmony', childId: 'melodic_minor_piano' },
  { parentId: 'minor_scales', childId: 'melodic_minor_piano' },
  { parentId: 'pitch_and_harmony', childId: 'a_minor' },
  { parentId: 'minor_key_signatures', childId: 'a_minor' },
  { parentId: 'pitch_and_harmony', childId: 'sharp_minors' },
  { parentId: 'minor_key_signatures', childId: 'sharp_minors' },
  { parentId: 'pitch_and_harmony', childId: 'flat_minors' },
  { parentId: 'minor_key_signatures', childId: 'flat_minors' },
  { parentId: 'pitch_and_harmony', childId: 'parallel_keys' },
  { parentId: 'minor_key_signatures', childId: 'parallel_keys' },
  { parentId: 'pitch_and_harmony', childId: 'relative_keys' },
  { parentId: 'minor_key_signatures', childId: 'relative_keys' },
  { parentId: 'pitch_and_harmony', childId: 'scale_degree_names' },
  { parentId: 'scale_degrees', childId: 'scale_degree_names' },
  { parentId: 'pitch_and_harmony', childId: 'scale_degree_conversion_major' },
  { parentId: 'scale_degrees', childId: 'scale_degree_conversion_major' },
  { parentId: 'pitch_and_harmony', childId: 'scale_degree_conversion_minor' },
  { parentId: 'scale_degrees', childId: 'scale_degree_conversion_minor' },
  { parentId: 'pitch_and_harmony', childId: 'generic_intervals' },
  { parentId: 'intervals', childId: 'generic_intervals' },
  { parentId: 'pitch_and_harmony', childId: 'interval_groups' },
  { parentId: 'intervals', childId: 'interval_groups' },
  { parentId: 'pitch_and_harmony', childId: 'simple_and_compound_intervals' },
  { parentId: 'intervals', childId: 'simple_and_compound_intervals' },
  { parentId: 'pitch_and_harmony', childId: 'ascending_major_perfect' },
  { parentId: 'intervals', childId: 'ascending_major_perfect' },
  { parentId: 'pitch_and_harmony', childId: 'ascending_others' },
  { parentId: 'intervals', childId: 'ascending_others' },
  { parentId: 'pitch_and_harmony', childId: 'descending_minor_perfect' },
  { parentId: 'intervals', childId: 'descending_minor_perfect' },
  { parentId: 'pitch_and_harmony', childId: 'descending_others' },
  { parentId: 'intervals', childId: 'descending_others' },
  { parentId: 'pitch_and_harmony', childId: 'interval_inversion' },
  { parentId: 'intervals', childId: 'interval_inversion' },
  { parentId: 'pitch_and_harmony', childId: 'enharmonic_intervals' },
  { parentId: 'intervals', childId: 'enharmonic_intervals' },
  { parentId: 'pitch_and_harmony', childId: 'spelling_triads' },
  { parentId: 'triads', childId: 'spelling_triads' },
  { parentId: 'pitch_and_harmony', childId: 'major_triads' },
  { parentId: 'triads', childId: 'major_triads' },
  { parentId: 'pitch_and_harmony', childId: 'minor_triads' },
  { parentId: 'triads', childId: 'minor_triads' },
  { parentId: 'pitch_and_harmony', childId: 'augmented_triads' },
  { parentId: 'triads', childId: 'augmented_triads' },
  { parentId: 'pitch_and_harmony', childId: 'diminished_triads' },
  { parentId: 'triads', childId: 'diminished_triads' },
  { parentId: 'pitch_and_harmony', childId: 'triad_spacing' },
  { parentId: 'triads', childId: 'triad_spacing' },
  { parentId: 'pitch_and_harmony', childId: 'triad_inversion' },
  { parentId: 'triads', childId: 'triad_inversion' },
  { parentId: 'pitch_and_harmony', childId: 'triad_figured_bass' },
  { parentId: 'triads', childId: 'triad_figured_bass' },
  { parentId: 'pitch_and_harmony', childId: 'triad_rn_qualities_major' },
  { parentId: 'roman_numerals', childId: 'triad_rn_qualities_major' },
  { parentId: 'pitch_and_harmony', childId: 'triad_rn_qualities_minor' },
  { parentId: 'roman_numerals', childId: 'triad_rn_qualities_minor' },
  { parentId: 'pitch_and_harmony', childId: 'seventh_rn_qualities_major' },
  { parentId: 'roman_numerals', childId: 'seventh_rn_qualities_major' },
  { parentId: 'pitch_and_harmony', childId: 'seventh_rn_qualities_minor' },
  { parentId: 'roman_numerals', childId: 'seventh_rn_qualities_minor' },
  { parentId: 'pitch_and_harmony', childId: 'triad_rn_major' },
  { parentId: 'roman_numerals', childId: 'triad_rn_major' },
  { parentId: 'pitch_and_harmony', childId: 'triad_rn_minor' },
  { parentId: 'roman_numerals', childId: 'triad_rn_minor' },
  { parentId: 'pitch_and_harmony', childId: 'seventh_rn_major' },
  { parentId: 'roman_numerals', childId: 'seventh_rn_major' },
  { parentId: 'pitch_and_harmony', childId: 'seventh_rn_minor' },
  { parentId: 'roman_numerals', childId: 'seventh_rn_minor' },
  { parentId: 'pitch_and_harmony', childId: 'raise_the_leading_tone' },
  { parentId: 'roman_numerals', childId: 'raise_the_leading_tone' },
  { parentId: 'pitch_and_harmony', childId: 'rn_open_spacing' },
  { parentId: 'roman_numerals', childId: 'rn_open_spacing' },
  { parentId: 'pitch_and_harmony', childId: 'rn_close_spacing' },
  { parentId: 'roman_numerals', childId: 'rn_close_spacing' },
  { parentId: 'pitch_and_harmony', childId: 'rn_inversion' },
  { parentId: 'roman_numerals', childId: 'rn_inversion' },
  { parentId: 'pitch_and_harmony', childId: 'seventh_chord_spelling' },
  { parentId: 'seventh_chords', childId: 'seventh_chord_spelling' },
  { parentId: 'pitch_and_harmony', childId: 'seventh_chord_types' },
  { parentId: 'seventh_chords', childId: 'seventh_chord_types' },
  { parentId: 'pitch_and_harmony', childId: 'major_seventh_chords' },
  { parentId: 'seventh_chords', childId: 'major_seventh_chords' },
  { parentId: 'pitch_and_harmony', childId: 'minor_seventh_chords' },
  { parentId: 'seventh_chords', childId: 'minor_seventh_chords' },
  { parentId: 'pitch_and_harmony', childId: 'dominant_seventh_chords' },
  { parentId: 'seventh_chords', childId: 'dominant_seventh_chords' },
  { parentId: 'pitch_and_harmony', childId: 'diminished_seventh_chords' },
  { parentId: 'seventh_chords', childId: 'diminished_seventh_chords' },
  { parentId: 'pitch_and_harmony', childId: 'half_diminished_seventh_chords' },
  { parentId: 'seventh_chords', childId: 'half_diminished_seventh_chords' },
  { parentId: 'pitch_and_harmony', childId: 'seventh_chord_spacing' },
  { parentId: 'seventh_chords', childId: 'seventh_chord_spacing' },
  { parentId: 'pitch_and_harmony', childId: 'seventh_chord_inversion' },
  { parentId: 'seventh_chords', childId: 'seventh_chord_inversion' },
  { parentId: 'pitch_and_harmony', childId: 'seventh_chord_figured_bass' },
  { parentId: 'seventh_chords', childId: 'seventh_chord_figured_bass' },
  { parentId: 'pitch_and_harmony', childId: 'alto_clef' },
  { parentId: 'c_clefs', childId: 'alto_clef' },
  { parentId: 'pitch_and_harmony', childId: 'tenor_clef' },
  { parentId: 'c_clefs', childId: 'tenor_clef' },
  { parentId: 'pitch_and_harmony', childId: 'soprano_clef' },
  { parentId: 'c_clefs', childId: 'soprano_clef' },
  { parentId: 'pitch_and_harmony', childId: 'mezzo_soprano_clef' },
  { parentId: 'c_clefs', childId: 'mezzo_soprano_clef' },
  { parentId: 'pitch_and_harmony', childId: 'baritone_clef' },
  { parentId: 'c_clefs', childId: 'baritone_clef' },
  { parentId: 'ear_training', childId: 'contour_basic' },
  { parentId: 'melodic_contour', childId: 'contour_basic' },
  { parentId: 'ear_training', childId: 'contour_high_low' },
  { parentId: 'melodic_contour', childId: 'contour_high_low' },
  { parentId: 'ear_training', childId: 'contour_high_medium_low_easy' },
  { parentId: 'melodic_contour', childId: 'contour_high_medium_low_easy' },
  { parentId: 'ear_training', childId: 'contour_high_medium_low_hard' },
  { parentId: 'melodic_contour', childId: 'contour_high_medium_low_hard' },
  { parentId: 'ear_training', childId: 'contour_four_level_easy' },
  { parentId: 'melodic_contour', childId: 'contour_four_level_easy' },
  { parentId: 'ear_training', childId: 'contour_four_level_hard' },
  { parentId: 'melodic_contour', childId: 'contour_four_level_hard' },
  { parentId: 'ear_training', childId: 'conjunct_disjunct_basic' },
  { parentId: 'conjunct_disjunct', childId: 'conjunct_disjunct_basic' },
  { parentId: 'ear_training', childId: 'conjunct_disjunct_direction_changes' },
  { parentId: 'conjunct_disjunct', childId: 'conjunct_disjunct_direction_changes' },
  { parentId: 'ear_training', childId: 'conjunct_disjunct_steps_vs_thirds' },
  { parentId: 'conjunct_disjunct', childId: 'conjunct_disjunct_steps_vs_thirds' },
  { parentId: 'ear_training', childId: 'conjunct_disjunct_2_note' },
  { parentId: 'conjunct_disjunct', childId: 'conjunct_disjunct_2_note' },
  { parentId: 'ear_training', childId: 'major_scale_solfege' },
  { parentId: 'solfege_et', childId: 'major_scale_solfege' },
  { parentId: 'ear_training', childId: 'major_scale_hand_signs' },
  { parentId: 'solfege_et', childId: 'major_scale_hand_signs' },
  { parentId: 'ear_training', childId: 'identify_tonic_c_major' },
  { parentId: 'solfege_et', childId: 'identify_tonic_c_major' },
  { parentId: 'ear_training', childId: 'identify_tonic_major_sharp_keys' },
  { parentId: 'solfege_et', childId: 'identify_tonic_major_sharp_keys' },
  { parentId: 'ear_training', childId: 'identify_tonic_major_flat_keys' },
  { parentId: 'solfege_et', childId: 'identify_tonic_major_flat_keys' },
  { parentId: 'ear_training', childId: 'labeling_solfege_major' },
  { parentId: 'solfege_et', childId: 'labeling_solfege_major' },
  { parentId: 'ear_training', childId: 'writing_solfege_major' },
  { parentId: 'solfege_et', childId: 'writing_solfege_major' },
  { parentId: 'ear_training', childId: 'natural_minor_scale_solfege' },
  { parentId: 'solfege_et', childId: 'natural_minor_scale_solfege' },
  { parentId: 'ear_training', childId: 'natural_minor_scale_hand_signs' },
  { parentId: 'solfege_et', childId: 'natural_minor_scale_hand_signs' },
  { parentId: 'ear_training', childId: 'identify_tonic_a_minor' },
  { parentId: 'solfege_et', childId: 'identify_tonic_a_minor' },
  { parentId: 'ear_training', childId: 'identify_tonic_minor_sharp_keys' },
  { parentId: 'solfege_et', childId: 'identify_tonic_minor_sharp_keys' },
  { parentId: 'ear_training', childId: 'identify_tonic_minor_flat_keys' },
  { parentId: 'solfege_et', childId: 'identify_tonic_minor_flat_keys' },
  { parentId: 'ear_training', childId: 'labeling_solfege_natural_minor' },
  { parentId: 'solfege_et', childId: 'labeling_solfege_natural_minor' },
  { parentId: 'ear_training', childId: 'writing_solfege_natural_minor' },
  { parentId: 'solfege_et', childId: 'writing_solfege_natural_minor' },
  { parentId: 'ear_training', childId: 'harmonic_minor_scale_solfege' },
  { parentId: 'solfege_et', childId: 'harmonic_minor_scale_solfege' },
  { parentId: 'ear_training', childId: 'harmonic_minor_scale_hand_signs' },
  { parentId: 'solfege_et', childId: 'harmonic_minor_scale_hand_signs' },
  { parentId: 'ear_training', childId: 'labeling_solfege_harmonic_minor' },
  { parentId: 'solfege_et', childId: 'labeling_solfege_harmonic_minor' },
  { parentId: 'ear_training', childId: 'writing_solfege_harmonic_minor' },
  { parentId: 'solfege_et', childId: 'writing_solfege_harmonic_minor' },
  { parentId: 'ear_training', childId: 'melodic_minor_scale_solfege' },
  { parentId: 'solfege_et', childId: 'melodic_minor_scale_solfege' },
  { parentId: 'ear_training', childId: 'melodic_minor_scale_hand_signs' },
  { parentId: 'solfege_et', childId: 'melodic_minor_scale_hand_signs' },
  { parentId: 'ear_training', childId: 'labeling_solfege_all_minors' },
  { parentId: 'solfege_et', childId: 'labeling_solfege_all_minors' },
  { parentId: 'ear_training', childId: 'writing_solfege_all_minors' },
  { parentId: 'solfege_et', childId: 'writing_solfege_all_minors' },
  { parentId: 'ear_training', childId: 'tonic_or_not' },
  { parentId: 'scale_degree_et', childId: 'tonic_or_not' },
  { parentId: 'ear_training', childId: 'sd_123_major' },
  { parentId: 'scale_degree_et', childId: 'sd_123_major' },
  { parentId: 'ear_training', childId: 'sd_123_minor' },
  { parentId: 'scale_degree_et', childId: 'sd_123_minor' },
  { parentId: 'ear_training', childId: 'sd_12345_major' },
  { parentId: 'scale_degree_et', childId: 'sd_12345_major' },
  { parentId: 'ear_training', childId: 'sd_12345_minor' },
  { parentId: 'scale_degree_et', childId: 'sd_12345_minor' },
  { parentId: 'ear_training', childId: 'sd_5678_major' },
  { parentId: 'scale_degree_et', childId: 'sd_5678_major' },
  { parentId: 'ear_training', childId: 'sd_5678_minor' },
  { parentId: 'scale_degree_et', childId: 'sd_5678_minor' },
  { parentId: 'ear_training', childId: 'sd_5678_all_minors' },
  { parentId: 'scale_degree_et', childId: 'sd_5678_all_minors' },
  { parentId: 'ear_training', childId: 'sd_1_to_8_major' },
  { parentId: 'scale_degree_et', childId: 'sd_1_to_8_major' },
  { parentId: 'ear_training', childId: 'sd_1_to_8_minor' },
  { parentId: 'scale_degree_et', childId: 'sd_1_to_8_minor' },
  { parentId: 'ear_training', childId: 'sd_1_to_8_major_w_octaves' },
  { parentId: 'scale_degree_et', childId: 'sd_1_to_8_major_w_octaves' },
  { parentId: 'ear_training', childId: 'sd_1_to_8_minor_w_octaves' },
  { parentId: 'scale_degree_et', childId: 'sd_1_to_8_minor_w_octaves' },
  { parentId: 'ear_training', childId: 'sd_frag_stepwise_major' },
  { parentId: 'muti_note_degree_et', childId: 'sd_frag_stepwise_major' },
  { parentId: 'ear_training', childId: 'sd_frag_stepwise_minor' },
  { parentId: 'muti_note_degree_et', childId: 'sd_frag_stepwise_minor' },
  { parentId: 'ear_training', childId: 'sd_frag_123_major' },
  { parentId: 'muti_note_degree_et', childId: 'sd_frag_123_major' },
  { parentId: 'ear_training', childId: 'sd_frag_123_minor' },
  { parentId: 'muti_note_degree_et', childId: 'sd_frag_123_minor' },
  { parentId: 'ear_training', childId: 'sd_frag_12345_major' },
  { parentId: 'muti_note_degree_et', childId: 'sd_frag_12345_major' },
  { parentId: 'ear_training', childId: 'sd_frag_12345_minor' },
  { parentId: 'muti_note_degree_et', childId: 'sd_frag_12345_minor' },
  { parentId: 'ear_training', childId: 'sd_frag_5678_major' },
  { parentId: 'muti_note_degree_et', childId: 'sd_frag_5678_major' },
  { parentId: 'ear_training', childId: 'sd_frag_5678_minor' },
  { parentId: 'muti_note_degree_et', childId: 'sd_frag_5678_minor' },
  { parentId: 'ear_training', childId: 'sd_frag_5678_all_minors' },
  { parentId: 'muti_note_degree_et', childId: 'sd_frag_5678_all_minors' },
  { parentId: 'ear_training', childId: 'sd_frag_1_to_8_major' },
  { parentId: 'muti_note_degree_et', childId: 'sd_frag_1_to_8_major' },
  { parentId: 'ear_training', childId: 'sd_frag_1_to_8_minor' },
  { parentId: 'muti_note_degree_et', childId: 'sd_frag_1_to_8_minor' },
  { parentId: 'ear_training', childId: 'sd_frag_1_to_8_major_w_octaves' },
  { parentId: 'muti_note_degree_et', childId: 'sd_frag_1_to_8_major_w_octaves' },
  { parentId: 'ear_training', childId: 'sd_frag_1_to_8_minor_w_octaves' },
  { parentId: 'muti_note_degree_et', childId: 'sd_frag_1_to_8_minor_w_octaves' },
  { parentId: 'ear_training', childId: 'asc_m2_et' },
  { parentId: 'melodic_intervals', childId: 'asc_m2_et' },
  { parentId: 'ear_training', childId: 'asc_M2_et' },
  { parentId: 'melodic_intervals', childId: 'asc_M2_et' },
  { parentId: 'ear_training', childId: 'asc_m3_et' },
  { parentId: 'melodic_intervals', childId: 'asc_m3_et' },
  { parentId: 'ear_training', childId: 'asc_M3_et' },
  { parentId: 'melodic_intervals', childId: 'asc_M3_et' },
  { parentId: 'ear_training', childId: 'asc_P4_et' },
  { parentId: 'melodic_intervals', childId: 'asc_P4_et' },
  { parentId: 'ear_training', childId: 'asc_TT_et' },
  { parentId: 'melodic_intervals', childId: 'asc_TT_et' },
  { parentId: 'ear_training', childId: 'asc_P5_et' },
  { parentId: 'melodic_intervals', childId: 'asc_P5_et' },
  { parentId: 'ear_training', childId: 'asc_m6_et' },
  { parentId: 'melodic_intervals', childId: 'asc_m6_et' },
  { parentId: 'ear_training', childId: 'asc_M6_et' },
  { parentId: 'melodic_intervals', childId: 'asc_M6_et' },
  { parentId: 'ear_training', childId: 'asc_m7_et' },
  { parentId: 'melodic_intervals', childId: 'asc_m7_et' },
  { parentId: 'ear_training', childId: 'asc_M7_et' },
  { parentId: 'melodic_intervals', childId: 'asc_M7_et' },
  { parentId: 'ear_training', childId: 'asc_P8_et' },
  { parentId: 'melodic_intervals', childId: 'asc_P8_et' },
  { parentId: 'ear_training', childId: 'asc_compound_et' },
  { parentId: 'melodic_intervals', childId: 'asc_compound_et' },
  { parentId: 'ear_training', childId: 'dsc_m2_et' },
  { parentId: 'melodic_intervals', childId: 'dsc_m2_et' },
  { parentId: 'ear_training', childId: 'dsc_M2_et' },
  { parentId: 'melodic_intervals', childId: 'dsc_M2_et' },
  { parentId: 'ear_training', childId: 'dsc_m3_et' },
  { parentId: 'melodic_intervals', childId: 'dsc_m3_et' },
  { parentId: 'ear_training', childId: 'dsc_M3_et' },
  { parentId: 'melodic_intervals', childId: 'dsc_M3_et' },
  { parentId: 'ear_training', childId: 'dsc_P4_et' },
  { parentId: 'melodic_intervals', childId: 'dsc_P4_et' },
  { parentId: 'ear_training', childId: 'dsc_TT_et' },
  { parentId: 'melodic_intervals', childId: 'dsc_TT_et' },
  { parentId: 'ear_training', childId: 'dsc_P5_et' },
  { parentId: 'melodic_intervals', childId: 'dsc_P5_et' },
  { parentId: 'ear_training', childId: 'dsc_m6_et' },
  { parentId: 'melodic_intervals', childId: 'dsc_m6_et' },
  { parentId: 'ear_training', childId: 'dsc_M6_et' },
  { parentId: 'melodic_intervals', childId: 'dsc_M6_et' },
  { parentId: 'ear_training', childId: 'dsc_m7_et' },
  { parentId: 'melodic_intervals', childId: 'dsc_m7_et' },
  { parentId: 'ear_training', childId: 'dsc_M7_et' },
  { parentId: 'melodic_intervals', childId: 'dsc_M7_et' },
  { parentId: 'ear_training', childId: 'dsc_P8_et' },
  { parentId: 'melodic_intervals', childId: 'dsc_P8_et' },
  { parentId: 'ear_training', childId: 'dsc_compound_et' },
  { parentId: 'melodic_intervals', childId: 'dsc_compound_et' },
  { parentId: 'ear_training', childId: 'm2_harmonic' },
  { parentId: 'harmonic_intervals', childId: 'm2_harmonic' },
  { parentId: 'ear_training', childId: 'M2_harmonic' },
  { parentId: 'harmonic_intervals', childId: 'M2_harmonic' },
  { parentId: 'ear_training', childId: 'm3_harmonic' },
  { parentId: 'harmonic_intervals', childId: 'm3_harmonic' },
  { parentId: 'ear_training', childId: 'M3_harmonic' },
  { parentId: 'harmonic_intervals', childId: 'M3_harmonic' },
  { parentId: 'ear_training', childId: 'P4_harmonic' },
  { parentId: 'harmonic_intervals', childId: 'P4_harmonic' },
  { parentId: 'ear_training', childId: 'TT_harmonic' },
  { parentId: 'harmonic_intervals', childId: 'TT_harmonic' },
  { parentId: 'ear_training', childId: 'P5_harmonic' },
  { parentId: 'harmonic_intervals', childId: 'P5_harmonic' },
  { parentId: 'ear_training', childId: 'm6_harmonic' },
  { parentId: 'harmonic_intervals', childId: 'm6_harmonic' },
  { parentId: 'ear_training', childId: 'M6_harmonic' },
  { parentId: 'harmonic_intervals', childId: 'M6_harmonic' },
  { parentId: 'ear_training', childId: 'm7_harmonic' },
  { parentId: 'harmonic_intervals', childId: 'm7_harmonic' },
  { parentId: 'ear_training', childId: 'M7_harmonic' },
  { parentId: 'harmonic_intervals', childId: 'M7_harmonic' },
  { parentId: 'ear_training', childId: 'P8_harmonic' },
  { parentId: 'harmonic_intervals', childId: 'P8_harmonic' },
  { parentId: 'ear_training', childId: 'compound_harmonic' },
  { parentId: 'harmonic_intervals', childId: 'compound_harmonic' },
  { parentId: 'ear_training', childId: 'major_triad_et' },
  { parentId: 'triads_et', childId: 'major_triad_et' },
  { parentId: 'ear_training', childId: 'minor_triad_et' },
  { parentId: 'triads_et', childId: 'minor_triad_et' },
  { parentId: 'ear_training', childId: 'augmented_triad_et' },
  { parentId: 'triads_et', childId: 'augmented_triad_et' },
  { parentId: 'ear_training', childId: 'diminshed_triad_et' },
  { parentId: 'triads_et', childId: 'diminshed_triad_et' },
  { parentId: 'ear_training', childId: 'arp_triad_et' },
  { parentId: 'triads_et', childId: 'arp_triad_et' },
  { parentId: 'ear_training', childId: 'close_triad_et' },
  { parentId: 'triads_et', childId: 'close_triad_et' },
  { parentId: 'ear_training', childId: 'open_triad_et' },
  { parentId: 'triads_et', childId: 'open_triad_et' },
  { parentId: 'ear_training', childId: 'triad_inversions_et' },
  { parentId: 'triads_et', childId: 'triad_inversions_et' },
  { parentId: 'ear_training', childId: 'triad_soprano_factor_et' },
  { parentId: 'triads_et', childId: 'triad_soprano_factor_et' },
  { parentId: 'ear_training', childId: 'maj_7_chords_et' },
  { parentId: 'seventh_chord_et', childId: 'maj_7_chords_et' },
  { parentId: 'ear_training', childId: 'min_7_chords_et' },
  { parentId: 'seventh_chord_et', childId: 'min_7_chords_et' },
  { parentId: 'ear_training', childId: 'dom_7_chords_et' },
  { parentId: 'seventh_chord_et', childId: 'dom_7_chords_et' },
  { parentId: 'ear_training', childId: 'dim_7_chords_et' },
  { parentId: 'seventh_chord_et', childId: 'dim_7_chords_et' },
  { parentId: 'ear_training', childId: 'half_dim_7_chords_et' },
  { parentId: 'seventh_chord_et', childId: 'half_dim_7_chords_et' },
  { parentId: 'ear_training', childId: 'arp_7_chords_et' },
  { parentId: 'seventh_chord_et', childId: 'arp_7_chords_et' },
  { parentId: 'ear_training', childId: 'close_7_chords_et' },
  { parentId: 'seventh_chord_et', childId: 'close_7_chords_et' },
  { parentId: 'ear_training', childId: 'open_7_chords_et' },
  { parentId: 'seventh_chord_et', childId: 'open_7_chords_et' },
  { parentId: 'ear_training', childId: 'seventh_chord_inversions_et' },
  { parentId: 'seventh_chord_et', childId: 'seventh_chord_inversions_et' },
  { parentId: 'ear_training', childId: 'seventh_chord_soprano_factor' },
  { parentId: 'seventh_chord_et', childId: 'seventh_chord_soprano_factor' },
  { parentId: 'rhythm', childId: 'basic_rhythm_math' },
  { parentId: 'rhythm_math', childId: 'basic_rhythm_math' },
  { parentId: 'rhythm', childId: 'dotted_rhythm_math' },
  { parentId: 'rhythm_math', childId: 'dotted_rhythm_math' },
  { parentId: 'rhythm', childId: 'simple_vs_compound' },
  { parentId: 'rhythm_math', childId: 'simple_vs_compound' },
  { parentId: 'rhythm', childId: 'dup_trip_quad' },
  { parentId: 'rhythm_math', childId: 'dup_trip_quad' },
  { parentId: 'rhythm', childId: 'meter_beat_value' },
  { parentId: 'rhythm_math', childId: 'meter_beat_value' },
  { parentId: 'rhythm', childId: 'meter_id_simple' },
  { parentId: 'rhythm_math', childId: 'meter_id_simple' },
  { parentId: 'rhythm', childId: 'meter_id_compound' },
  { parentId: 'rhythm_math', childId: 'meter_id_compound' },
  { parentId: 'rhythm', childId: 'meter_completion_simple' },
  { parentId: 'rhythm_math', childId: 'meter_completion_simple' },
  { parentId: 'rhythm', childId: 'meter_completion_compound' },
  { parentId: 'rhythm_math', childId: 'meter_completion_compound' },
  { parentId: 'rhythm', childId: 'meter_completion_cut_time' },
  { parentId: 'rhythm_math', childId: 'meter_completion_cut_time' },
  { parentId: 'rhythm', childId: 'count_labeling_simple' },
  { parentId: 'rhythm_math', childId: 'count_labeling_simple' },
  { parentId: 'rhythm', childId: 'count_labeling_simple_small_subdivisions' },
  { parentId: 'rhythm_math', childId: 'count_labeling_simple_small_subdivisions' },
  { parentId: 'rhythm', childId: 'count_labeling_compound' },
  { parentId: 'rhythm_math', childId: 'count_labeling_compound' },
  { parentId: 'rhythm', childId: 'count_labeling_compound_small_subdivisions' },
  { parentId: 'rhythm_math', childId: 'count_labeling_compound_small_subdivisions' },
  { parentId: 'rhythm', childId: 'S:q' },
  { parentId: 'simple_first_subdivision', childId: 'S:q' },
  { parentId: 'rhythm', childId: 'S:ee' },
  { parentId: 'simple_first_subdivision', childId: 'S:ee' },
  { parentId: 'rhythm', childId: 'S:qr' },
  { parentId: 'simple_first_subdivision', childId: 'S:qr' },
  { parentId: 'rhythm', childId: 'S:h' },
  { parentId: 'simple_first_subdivision', childId: 'S:h' },
  { parentId: 'rhythm', childId: 'S:hr' },
  { parentId: 'simple_first_subdivision', childId: 'S:hr' },
  { parentId: 'rhythm', childId: 'S:w' },
  { parentId: 'simple_first_subdivision', childId: 'S:w' },
  { parentId: 'rhythm', childId: 'S:wr' },
  { parentId: 'simple_first_subdivision', childId: 'S:wr' },
  { parentId: 'rhythm', childId: 'C:q.' },
  { parentId: 'compound_beat_and_subdivision', childId: 'C:q.' },
  { parentId: 'rhythm', childId: 'C:eee' },
  { parentId: 'compound_beat_and_subdivision', childId: 'C:eee' },
  { parentId: 'rhythm', childId: 'C:q.r' },
  { parentId: 'compound_beat_and_subdivision', childId: 'C:q.r' },
  { parentId: 'rhythm', childId: 'C:h.' },
  { parentId: 'compound_beat_and_subdivision', childId: 'C:h.' },
  { parentId: 'rhythm', childId: 'C:h.r' },
  { parentId: 'compound_beat_and_subdivision', childId: 'C:h.r' },
  { parentId: 'rhythm', childId: 'C:w.' },
  { parentId: 'compound_beat_and_subdivision', childId: 'C:w.' },
  { parentId: 'rhythm', childId: 'C:w.r' },
  { parentId: 'compound_beat_and_subdivision', childId: 'C:w.r' },
  { parentId: 'rhythm', childId: 'S:ere' },
  { parentId: 'simple_offbeats_and_dotted', childId: 'S:ere' },
  { parentId: 'rhythm', childId: 'S:eer' },
  { parentId: 'simple_offbeats_and_dotted', childId: 'S:eer' },
  { parentId: 'rhythm', childId: 'S:h.' },
  { parentId: 'simple_offbeats_and_dotted', childId: 'S:h.' },
  { parentId: 'rhythm', childId: 'S:q.e' },
  { parentId: 'simple_offbeats_and_dotted', childId: 'S:q.e' },
  { parentId: 'rhythm', childId: 'S:q.re' },
  { parentId: 'simple_offbeats_and_dotted', childId: 'S:q.re' },
  { parentId: 'rhythm', childId: 'S:h.r' },
  { parentId: 'simple_offbeats_and_dotted', childId: 'S:h.r' },
  { parentId: 'rhythm', childId: 'C:qe' },
  { parentId: 'compound_patterns_first_subdivision', childId: 'C:qe' },
  { parentId: 'rhythm', childId: 'C:qre' },
  { parentId: 'compound_patterns_first_subdivision', childId: 'C:qre' },
  { parentId: 'rhythm', childId: 'C:eq' },
  { parentId: 'compound_patterns_first_subdivision', childId: 'C:eq' },
  { parentId: 'rhythm', childId: 'C:eeer' },
  { parentId: 'compound_patterns_first_subdivision', childId: 'C:eeer' },
  { parentId: 'rhythm', childId: 'C:eqr' },
  { parentId: 'compound_patterns_first_subdivision', childId: 'C:eqr' },
  { parentId: 'rhythm', childId: 'C:qer' },
  { parentId: 'compound_patterns_first_subdivision', childId: 'C:qer' },
  { parentId: 'rhythm', childId: 'C:eree' },
  { parentId: 'compound_patterns_first_subdivision', childId: 'C:eree' },
  { parentId: 'rhythm', childId: 'C:erq' },
  { parentId: 'compound_patterns_first_subdivision', childId: 'C:erq' },
  { parentId: 'rhythm', childId: 'C:eere' },
  { parentId: 'compound_patterns_first_subdivision', childId: 'C:eere' },
  { parentId: 'rhythm', childId: 'C:ereer' },
  { parentId: 'compound_patterns_first_subdivision', childId: 'C:ereer' },
  { parentId: 'rhythm', childId: 'H:h' },
  { parentId: 'cut_time_basic', childId: 'H:h' },
  { parentId: 'rhythm', childId: 'H:qq' },
  { parentId: 'cut_time_basic', childId: 'H:qq' },
  { parentId: 'rhythm', childId: 'H:hr' },
  { parentId: 'cut_time_basic', childId: 'H:hr' },
  { parentId: 'rhythm', childId: 'H:w' },
  { parentId: 'cut_time_basic', childId: 'H:w' },
  { parentId: 'rhythm', childId: 'H:wr' },
  { parentId: 'cut_time_basic', childId: 'H:wr' },
  { parentId: 'rhythm', childId: 'H:qrq' },
  { parentId: 'cut_time_basic', childId: 'H:qrq' },
  { parentId: 'rhythm', childId: 'H:qqr' },
  { parentId: 'cut_time_basic', childId: 'H:qqr' },
  { parentId: 'rhythm', childId: 'H:h.q' },
  { parentId: 'cut_time_basic', childId: 'H:h.q' },
  { parentId: 'rhythm', childId: 'H:h.rq' },
  { parentId: 'cut_time_basic', childId: 'H:h.rq' },
  { parentId: 'rhythm', childId: 'S:eqe' },
  { parentId: 'simple_eighth_syncopations', childId: 'S:eqe' },
  { parentId: 'rhythm', childId: 'S:eq.' },
  { parentId: 'simple_eighth_syncopations', childId: 'S:eq.' },
  { parentId: 'rhythm', childId: 'S:eq.r' },
  { parentId: 'simple_eighth_syncopations', childId: 'S:eq.r' },
  { parentId: 'rhythm', childId: 'S:eqqe' },
  { parentId: 'simple_eighth_syncopations', childId: 'S:eqqe' },
  { parentId: 'rhythm', childId: 'S:erqqe' },
  { parentId: 'simple_eighth_syncopations', childId: 'S:erqqe' },
  { parentId: 'rhythm', childId: 'S:eqqqe' },
  { parentId: 'simple_eighth_syncopations', childId: 'S:eqqqe' },
  { parentId: 'rhythm', childId: 'S:erqqqe' },
  { parentId: 'simple_eighth_syncopations', childId: 'S:erqqqe' },
  { parentId: 'rhythm', childId: 'S:ssss' },
  { parentId: 'simple_sixteenth_groups', childId: 'S:ssss' },
  { parentId: 'rhythm', childId: 'S:sse' },
  { parentId: 'simple_sixteenth_groups', childId: 'S:sse' },
  { parentId: 'rhythm', childId: 'S:sser' },
  { parentId: 'simple_sixteenth_groups', childId: 'S:sser' },
  { parentId: 'rhythm', childId: 'S:ess' },
  { parentId: 'simple_sixteenth_groups', childId: 'S:ess' },
  { parentId: 'rhythm', childId: 'S:erss' },
  { parentId: 'simple_sixteenth_groups', childId: 'S:erss' },
  { parentId: 'rhythm', childId: 'S:srsss' },
  { parentId: 'simple_sixteenth_groups', childId: 'S:srsss' },
  { parentId: 'rhythm', childId: 'S:ssssr' },
  { parentId: 'simple_sixteenth_groups', childId: 'S:ssssr' },
  { parentId: 'rhythm', childId: 'C:ssssss' },
  { parentId: 'compound_sixteenth_groups', childId: 'C:ssssss' },
  { parentId: 'rhythm', childId: 'C:ssee' },
  { parentId: 'compound_sixteenth_groups', childId: 'C:ssee' },
  { parentId: 'rhythm', childId: 'C:sssse' },
  { parentId: 'compound_sixteenth_groups', childId: 'C:sssse' },
  { parentId: 'rhythm', childId: 'C:esse' },
  { parentId: 'compound_sixteenth_groups', childId: 'C:esse' },
  { parentId: 'rhythm', childId: 'C:essss' },
  { parentId: 'compound_sixteenth_groups', childId: 'C:essss' },
  { parentId: 'rhythm', childId: 'C:eess' },
  { parentId: 'compound_sixteenth_groups', childId: 'C:eess' },
  { parentId: 'rhythm', childId: 'C:ssess' },
  { parentId: 'compound_sixteenth_groups', childId: 'C:ssess' },
  { parentId: 'rhythm', childId: 'C:qrss' },
  { parentId: 'compound_sixteenth_groups', childId: 'C:qrss' },
  { parentId: 'rhythm', childId: 'C:erssss' },
  { parentId: 'compound_sixteenth_groups', childId: 'C:erssss' },
  { parentId: 'rhythm', childId: 'C:eress' },
  { parentId: 'compound_sixteenth_groups', childId: 'C:eress' },
  { parentId: 'rhythm', childId: 'C:ersse' },
  { parentId: 'compound_sixteenth_groups', childId: 'C:ersse' },
  { parentId: 'rhythm', childId: 'C:qss' },
  { parentId: 'compound_sixteenth_groups', childId: 'C:qss' },
  { parentId: 'rhythm', childId: 'C:ssq' },
  { parentId: 'compound_sixteenth_groups', childId: 'C:ssq' },
  { parentId: 'rhythm', childId: 'H:qhq' },
  { parentId: 'cut_time_syncopations', childId: 'H:qhq' },
  { parentId: 'rhythm', childId: 'H:qh.' },
  { parentId: 'cut_time_syncopations', childId: 'H:qh.' },
  { parentId: 'rhythm', childId: 'H:eeq' },
  { parentId: 'cut_time_syncopations', childId: 'H:eeq' },
  { parentId: 'rhythm', childId: 'H:eeqr' },
  { parentId: 'cut_time_syncopations', childId: 'H:eeqr' },
  { parentId: 'rhythm', childId: 'H:qee' },
  { parentId: 'cut_time_syncopations', childId: 'H:qee' },
  { parentId: 'rhythm', childId: 'H:qree' },
  { parentId: 'cut_time_syncopations', childId: 'H:qree' },
  { parentId: 'rhythm', childId: 'S:ses' },
  { parentId: 'simple_sixteenth_syncopations', childId: 'S:ses' },
  { parentId: 'rhythm', childId: 'S:e.s' },
  { parentId: 'simple_sixteenth_syncopations', childId: 'S:e.s' },
  { parentId: 'rhythm', childId: 'S:e.rs' },
  { parentId: 'simple_sixteenth_syncopations', childId: 'S:e.rs' },
  { parentId: 'rhythm', childId: 'S:se.' },
  { parentId: 'simple_sixteenth_syncopations', childId: 'S:se.' },
  { parentId: 'rhythm', childId: 'S:sre.' },
  { parentId: 'simple_sixteenth_syncopations', childId: 'S:sre.' },
  { parentId: 'rhythm', childId: 'C:e.se' },
  { parentId: 'compound_dotted_eighths', childId: 'C:e.se' },
  { parentId: 'rhythm', childId: 'C:ee.s' },
  { parentId: 'compound_dotted_eighths', childId: 'C:ee.s' },
  { parentId: 'rhythm', childId: 'C:se.e' },
  { parentId: 'compound_dotted_eighths', childId: 'C:se.e' },
  { parentId: 'rhythm', childId: 'C:ere.s' },
  { parentId: 'compound_dotted_eighths', childId: 'C:ere.s' },
  { parentId: 'rhythm', childId: 'C:sese' },
  { parentId: 'compound_sixteenth_syncopations', childId: 'C:sese' },
  { parentId: 'rhythm', childId: 'C:sesss' },
  { parentId: 'compound_sixteenth_syncopations', childId: 'C:sesss' },
  { parentId: 'rhythm', childId: 'C:e.rsss' },
  { parentId: 'compound_sixteenth_syncopations', childId: 'C:e.rsss' },
  { parentId: 'rhythm', childId: 'C:eses' },
  { parentId: 'compound_sixteenth_syncopations', childId: 'C:eses' },
  { parentId: 'rhythm', childId: 'C:erses' },
  { parentId: 'compound_sixteenth_syncopations', childId: 'C:erses' },
  { parentId: 'rhythm', childId: 'C:ssses' },
  { parentId: 'compound_sixteenth_syncopations', childId: 'C:ssses' },
  { parentId: 'rhythm', childId: 'C:e.e.' },
  { parentId: 'compound_sixteenth_syncopations', childId: 'C:e.e.' },
  { parentId: 'rhythm', childId: 'C:e.re.' },
  { parentId: 'compound_sixteenth_syncopations', childId: 'C:e.re.' },
  { parentId: 'rhythm', childId: 'C:ssse.' },
  { parentId: 'compound_sixteenth_syncopations', childId: 'C:ssse.' },
  { parentId: 'rhythm', childId: 'C:e.sss' },
  { parentId: 'compound_sixteenth_syncopations', childId: 'C:e.sss' },
  { parentId: 'pitch_and_harmony', childId: 'piano' },
  { parentId: 'pitch_and_harmony', childId: 'treble_clef' },
  { parentId: 'pitch_and_harmony', childId: 'accidentals' },
  { parentId: 'pitch_and_harmony', childId: 'bass_clef' },
  { parentId: 'pitch_and_harmony', childId: 'half_and_whole_steps' },
  { parentId: 'pitch_and_harmony', childId: 'major_scales' },
  { parentId: 'pitch_and_harmony', childId: 'major_key_signatures' },
  { parentId: 'pitch_and_harmony', childId: 'minor_scales' },
  { parentId: 'pitch_and_harmony', childId: 'minor_key_signatures' },
  { parentId: 'pitch_and_harmony', childId: 'scale_degrees' },
  { parentId: 'pitch_and_harmony', childId: 'intervals' },
  { parentId: 'pitch_and_harmony', childId: 'triads' },
  { parentId: 'pitch_and_harmony', childId: 'roman_numerals' },
  { parentId: 'pitch_and_harmony', childId: 'seventh_chords' },
  { parentId: 'pitch_and_harmony', childId: 'c_clefs' },
  { parentId: 'rhythm', childId: 'rhythm_math' },
  { parentId: 'rhythm', childId: 'simple_first_subdivision' },
  { parentId: 'rhythm', childId: 'compound_beat_and_subdivision' },
  { parentId: 'rhythm', childId: 'simple_offbeats_and_dotted' },
  { parentId: 'rhythm', childId: 'compound_patterns_first_subdivision' },
  { parentId: 'rhythm', childId: 'cut_time_basic' },
  { parentId: 'rhythm', childId: 'simple_eighth_syncopations' },
  { parentId: 'rhythm', childId: 'simple_sixteenth_groups' },
  { parentId: 'rhythm', childId: 'compound_sixteenth_groups' },
  { parentId: 'rhythm', childId: 'cut_time_syncopations' },
  { parentId: 'rhythm', childId: 'simple_sixteenth_syncopations' },
  { parentId: 'rhythm', childId: 'compound_dotted_eighths' },
  { parentId: 'rhythm', childId: 'compound_sixteenth_syncopations' },
  { parentId: 'ear_training', childId: 'melodic_contour' },
  { parentId: 'ear_training', childId: 'conjunct_disjunct' },
  { parentId: 'ear_training', childId: 'solfege_et' },
  { parentId: 'ear_training', childId: 'scale_degree_et' },
  { parentId: 'ear_training', childId: 'muti_note_degree_et' },
  { parentId: 'ear_training', childId: 'melodic_intervals' },
  { parentId: 'ear_training', childId: 'harmonic_intervals' },
  { parentId: 'ear_training', childId: 'triads_et' },
  { parentId: 'ear_training', childId: 'seventh_chord_et' },
  { parentId: 'ear_training', childId: 'melodic_fragments' },
  { parentId: 'ear_training', childId: 'melodic_dictation' },
];

export default knowledgeGraphEdges;
